import Head from 'next/head';
import { ImageBlob } from '~source/core/models/components/atoms/image-blob';
import useImageUrl from '~source/ui/hooks/formatter/useImageUrl/useImageUrl';

export type MetaTagProps = {
  metaTitle?: string;
  metaDescription?: string;
  twitter?: boolean;
  primaryImage?: ImageBlob | null;
  displayValue?: string;
  productInfo?: string;
};

export default function MetaTag({
  metaTitle,
  metaDescription,
  twitter,
  primaryImage,
  displayValue,
  productInfo,
}: MetaTagProps) {
  const imageSize = {
    width: 1000,
    height: 1000,
  };
  const imageUrl = useImageUrl(primaryImage || null, imageSize);
  return (
    <Head>
      {metaTitle && (
        <>
          <title>{metaTitle}</title>
          <meta key="og-title" property="og:title" content={metaTitle} />
        </>
      )}
      {metaDescription && (
        <>
          <meta
            key="description"
            name="description"
            content={metaDescription}
          />
          <meta
            key="og-description"
            property="og:description"
            content={metaDescription}
          />
        </>
      )}
      {imageUrl && (
        <>
          <meta key="og-image" property="og:image" content={imageUrl} />
          <meta
            key="og-image-width"
            property="og:image:width"
            content={imageSize.width.toString()}
          />
          <meta
            key="og-image-height"
            property="og:image:height"
            content={imageSize.height.toString()}
          />
        </>
      )}
      {twitter && (
        <>
          <meta key="twitter-card" name="twitter:card" content="summary" />
          {displayValue && (
            <meta
              key="twitter-title"
              name="twitter:title"
              content={displayValue}
            />
          )}
          {productInfo && (
            <meta
              key="twitter-description"
              name="twitter:description"
              content={productInfo}
            />
          )}
          {imageUrl && (
            <meta key="twitter-image" name="twitter:image" content={imageUrl} />
          )}
          <meta key="twitter-site" name="twitter:site" content="AFCAjax" />
        </>
      )}
    </Head>
  );
}
