import Head from 'next/head';
import { useRouter } from 'next/router';
import * as React from 'react';
import { AJAX_HREF } from '~source/constants';
import { ImageBlob } from '~source/core/models/components/atoms/image-blob';
import { ProductTeaser } from '~source/core/models/components/molecules/product-teaser';
import useImageUrl from '~source/ui/hooks/formatter/useImageUrl/useImageUrl';
import { createProductSchema } from '~source/ui/utils/product/create-product-schema';

type CategoryStructuredData = {
  products: ProductTeaser[];
  url: string;
  numberOfItems: number;
};

function Image(primaryImage: ImageBlob) {
  return useImageUrl(primaryImage, {
    width: 1000,
    height: 1000,
  });
}

function useMakeStructuredDataSchema({
  products,
  url,
  numberOfItems,
}: CategoryStructuredData) {
  const items = products.map((product) => {
    const {
      displayValue,
      path,
      primaryImage,
      price,
      description,
      category,
      backendId,
      sizes,
    } = product;
    const productUrl = `${AJAX_HREF}${path}`;
    const imageUrl = Image(primaryImage);
    const productSchema = createProductSchema({
      displayValue,
      category,
      productInfo: description,
      backendId,
      sizes,
      price,
      productUrl,
      imageUrl,
    });
    return productSchema;
  });
  return {
    '@context': 'https://schema.org/',
    '@type': 'ItemList',
    url,
    numberOfItems,
    itemListElement: items,
  };
}

interface StructuredProductCategoryHeadProps {
  products: ProductTeaser[];
}
const StructuredProductCategoryHead: React.FunctionComponent<StructuredProductCategoryHeadProps> =
  React.memo(({ products }) => {
    const numberOfItems = products.length;
    const { asPath } = useRouter();
    const url = `${AJAX_HREF}${asPath}`;
    const structuredData = useMakeStructuredDataSchema({
      products,
      url,
      numberOfItems,
    });
    const data = JSON.stringify(structuredData);

    return (
      <Head>
        <script
          type="application/ld+json"
          key={data}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        />
      </Head>
    );
  });

export default StructuredProductCategoryHead;
