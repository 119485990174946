import * as React from 'react';
import {
  SelectedFilterOptions,
  SetFilterFunction,
  SortDirection,
} from '~source/core/models/components/molecules/filters';
import { ProductTeaser } from '~source/core/models/components/molecules/product-teaser';
import { searchProductsByCollection } from '~source/core/services/eva/api/search-products';
import useLocale from '~source/ui/hooks/helper/useLocale/useLocale';

interface ProductOptions {
  productCollection: string | null;
  products: ProductTeaser[] | undefined;
  currentPage?: number;
}

export default function useFilteredProducts(productOptions: ProductOptions) {
  const { productCollection, products, currentPage } = productOptions;
  const locale = useLocale();
  const [filteredProducts, setFilteredProducts] = React.useState<
    ProductTeaser[]
  >(products ?? []);
  const [filters, _setFilters] = React.useState<SelectedFilterOptions>({});
  const [sortDirection, setSortDirection] =
    React.useState<SortDirection>('unsorted');
  const setFilter: SetFilterFunction = (filterProperty, value) => {
    _setFilters((prev) => ({
      ...prev,
      [filterProperty]: value,
    }));
  };
  React.useEffect(() => {
    if (!productCollection) return undefined;
    const { promise, abort } = searchProductsByCollection({
      collection: productCollection,
      filterProperties: filters,
      sortDirection,
      currentPage,
      locale,
    });

    promise.then((fetchedProducts) => {
      setFilteredProducts(fetchedProducts ? fetchedProducts.products : []);
    });

    return abort;
  }, [filters, sortDirection, productCollection, currentPage, locale]);

  return {
    filteredProducts,
    filters,
    pageNumber: currentPage || 1,
    setFilter,
    sortDirection,
    setSortDirection,
  };
}
