import { Price } from '~source/core/models/components/atoms/price';
import {
  SizeModel,
  SizeModelConfigurable,
} from '~source/core/models/components/atoms/size';

type ProductSchema = {
  displayValue: string;
  category?: string;
  productInfo?: string;
  backendId?: string;
  sizes?:
    | (SizeModel | SizeModelConfigurable | Pick<SizeModel, 'label'>)[]
    | null;
  price: Price;
  productUrl: string;
  imageUrl: string;
};

export const createProductSchema = ({
  displayValue,
  category,
  productInfo,
  backendId,
  sizes,
  price,
  productUrl,
  imageUrl,
}: ProductSchema) => {
  let currentPrice: number | null = price.original || null;
  if (price.sale !== null) currentPrice = price.sale;

  return {
    '@type': 'Product',
    '@id': productUrl,
    image: imageUrl,
    description: productInfo,
    url: productUrl,
    name: displayValue,
    category,
    sku: backendId,
    mpn: backendId,
    productID: backendId,
    size: sizes?.map((size) => size.label),
    offers: {
      '@type': 'offer',
      price: currentPrice,
    },
  };
};
